p.core {
    text-align: center;
    padding: 10px;
    height: 114px;

}
.card-header.main {
    height: 58px;
}
ol.order {
    display: inline;
}
.card-1 {
    margin-left: 29px;
}
p.core1 {
    height: auto;
}
p.core12 {
    height: 432px;
    text-align: center;
    margin: 10px;
}
.input-error {
    border-color: red !important;
  }
  .scrolls {
    overflow-x: scroll;
    width: auto;
}

.border-tab.nav-primary .nav-item .nav-link.active, .border-tab.nav-primary .nav-item .nav-link.show, .border-tab.nav-primary .nav-item .nav-link:focus, .border-tab.nav-primary .nav-item .nav-link:hover {
    color: white !important;
    background: #41B853;
    border: none;
}

.progress-bar {
    height: 100% !important;
}

li.nav-item {
    margin-right: 2px;
    
}

.nav-tabs {
    border-bottom: none !important;
}

.nav-tabs .nav-item .nav-link {
    border-bottom: 1px solid #000 !important;
}

li.nav-item {
    /* border: 1px solid gray; */
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}
li.nav-item :last-child
{
    margin-right: 0px;
}
.border-tab.nav-primary .nav-item .nav-link.active, .border-tab.nav-primary .nav-item .nav-link.show, .border-tab.nav-primary .nav-item .nav-link:focus {
    border-bottom: 2px solid black;
}
.border-tab.nav-tabs .nav-item .nav-link {
    font-size: 13px;
    color: black !important;
    text-align: center;
    padding: 10px 0px;
    border: 1px solid black;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    transition: .3s;
   
}
.rbc-row.rbc-month-header {
    background: black;
    color: white;
}
button {
    color: white ;
}
.rbc-toolbar {
    background: black;
    color: white !important;
    padding: 10px;
}
.rbc-toolbar button {
    border: 1px solid #ffffff !important;
    font-size: 14px;
}
table.life-planing-table.table.table-bordered {
    overflow-x: scroll;
    display: block;
}
.life-plan-table-width {
    display: block;
    /* width: 300px; */
    width: 200px;
}

.lifeprint-tr{
    width: auto;
    height: auto;
}

/* span.badge.rounded-pill.notificationicon.pull-right {
    margin-top: -30px;
} */
.question68 td {
    background-color: #fbc211;
}
.question68 .d-flex.justify-content-around
{
    background-color: #ffecb9;
}
.question67 td{
    background-color: #957447;
}

.question67 .d-flex.justify-content-around
{
    background-color: #e0d6cc;
}
.question66 td{
    background-color: #8f2b8d;
}

.question66 .d-flex.justify-content-around
{
    background-color: #dcbfdd;
}
.question65 td{
    background-color: #be2e2d;
}

.question65 .d-flex.justify-content-around
{
    background-color: #eec0c2;
}
.question64 td{
    background-color: #006e3d;
}

.question64 .d-flex.justify-content-around
{
    background-color:  #b2d3c2;
}
.question63 td{
    background-color: #f06426;
}

.question63 .d-flex.justify-content-around
{
    background-color: #fbd0bd;
}
.question62 td{
    background-color: #8dc643;
}

.question62 .d-flex.justify-content-around
{
    background-color: #ddecc3;
}
.question61 td{
    background-color: #159efc;
}

.question61 .d-flex.justify-content-around
{
    background-color: #b2e6fb;
}

.user-edit-sublink.d-none.d-md-flex.col-sm-2.col-12 {
    background: #373f52 !important;
}
.nav-notes .nav-item a.active
{
    background: #fff;
    border-radius: 5px;
    color: #000;
    display: flex;
    padding: 8px;
    width: 100%;
}
.nav-notes .nav-item a{
color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    width: 100%;
}

.question-container {
  margin-bottom: 20px;
}

.question-item {
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.btn-secondary {
  margin-right: 10px;
}

.btn-success {
  margin-right: 10px;
}

    /* .btn-primary {
    margin-left: auto;
    } */


.life-wheel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.life-wheel-svg {
    width: 100%;
    height: auto;
    max-width: 500px;
}

.life-wheel-svg line {
    stroke: #000;
    stroke-width: 0.5;
}

.life-wheel-svg circle {
    fill: red;
    cursor: pointer;
}

.life-wheel-svg circle:hover {
    fill: blue;
}

.textnots-container-persomality {
    margin-bottom: 20px;
}

.textnots-text {
    margin: 10px 0;
}

.life-wheel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.life-wheel-svg {
    width: 100%;
    height: auto;
    max-width: 500px;
    max-height: 500px;
}

.life-wheel-svg line {
    stroke: black;
    stroke-width: 0.5;
}

.life-wheel-svg circle {
    fill: red;
    cursor: pointer;
}

.life-wheel-svg circle.selected {
    fill: blue;
}

.card-header h5 {
    margin: 0;
}

.textnots-container-persomality {
    margin-bottom: 20px;
}

.textnots-text {
    margin: 10px 0;
}

.card-footer {
    display: flex;
    justify-content: flex-end;
}

.form-control.life-plan-area-personalos {
    display: block;
    width: 100%;
    font-family: inherit;
    color: rgb(79, 176, 174);
    padding-block-start: 0px;
    padding-block-end: var(--spacing-100);
    padding-inline: 0px;
    border: none;
    outline: none;
    border-radius: 0px;
    appearance: none;
    background-image: none;
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    transform: translateZ(0px);
    font-size: 30px;
    -webkit-font-smoothing: antialiased;
    line-height: unset;
    -webkit-text-fill-color:#6480c8;
    animation: 1ms ease 0s 1 normal none running native-autofill-in;
    transition: background-color 1e+08s, box-shadow 0.1s ease-out;
    box-shadow: rgba(79, 176, 174, 0.3) 0px 1px;
    background-color: transparent !important;
}

.life-plan-question-blueprint
{
    background-color: #16365c;
    color: #fff !important;
}
th.question-anster-th-life360 {
    background-color: #5592dc !important;
}
.completed-item {
    text-decoration: line-through;
}
.background-orange {
    background-color: #FFDDC1;
}
.background-green {
    background-color: #C1FFD7;
}
.background-blue {
    background-color: #C1EFFF;
}
.textnots-container-personality.mb-3 {
    padding: 20px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: lightgray;
}

.checked-label {
    text-decoration: line-through;
}

.col-md-12.align-items-center.d-flex.heading-reports {
    background: #9bc2e6;
    padding: 11px;
}
.section:nth-child(1) .radio-group_life_wheel {
    transform: translate(-49%, -23%) rotate(186deg) !important;
    top: 45%;
    left: 149px;
}
.section:nth-child(2) .radio-group_life_wheel {
    transform: translate(-49%, -23%) rotate(186deg) !important;
    top: 45%;
    left: 149px;
}

.section:nth-child(3) .radio-group_life_wheel {
    transform: translate(-49%, -23%) rotate(186deg) !important;
    top: 45%;
    left: 149px;
}
.section:nth-child(4) .radio-group_life_wheel {
    transform: translate(-49%, -23%) rotate(186deg) !important;
    top: 45%;
    left: 149px;
}
.section:nth-child(5) .radio-group_life_wheel {
    transform: translate(-49%, -23%) rotate(186deg) !important;
    top: 45%;
    left: 149px;
}
.section:nth-child(6) .radio-group_life_wheel {
    transform: translate(-49%, -23%) rotate(186deg) !important;
    top: 45%;
    left: 149px;
}
.section:nth-child(7) .radio-group_life_wheel {
    transform: translate(-49%, -23%) rotate(186deg) !important;
    top: 45%;
    left: 149px;
}
.section:nth-child(8) .radio-group_life_wheel {
    transform: translate(-49%, -23%) rotate(186deg) !important;
    top: 45%;
    left: 149px;
}
.percentage{
    font-size: 18px;
    font-weight: 700;
}
.header-tagline {
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
    color: #333; /* Adjust the color as needed */
  }
  
  .header-tagline p {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: bold;
  }
  