.mHisf {
    display: flex;
    align-items: stretch;
    background: #161b40 !important;
    width: 100%;
    background-color: #FFFFFF;
    min-height: 52px;
    border-bottom-width: 1px;
    border-bottom-color: #161b40 !important;
    border-bottom-style: solid !important;
    color: #fff !important;
}

.faZveH {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 100px;
    border-right: 1px solid #fff !important;
}

.bGOnmG {
    /* border: 1px solid #dee2e6 !important; */
}



.fwQJth {
    background-color: #161b40 !important;
    border-bottom: 0px solid #161b40 !important;
}



.is-invalid {
    border-color: red !important;
}

/* .life-wheel-tabel{
    width: auto;
    overflow-x: scroll;
} */


.hrfSzQ {
    display: flex;
    width: 100%;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
}


/* .gfKXFa {
    border: 1px solid #dee2e6 !important;
} */

.eZWyen {
    /* border: 1px solid #dee2e6 !important; */
}

.eXWrwD {
    margin: 0px 8px;
}

.go169520481 {
    /* justify-content: center; */
    display: none !important;
}

.progress-bar {
    width: 100%;
    font-size: 14px !important;
    background-color:#1650b4;
    height: 27px;
    border-radius: 5px;
    overflow: hidden;
}


.login-bg {
    padding: 40px;
    width: 100%;
    height: 100%;
    background: #c8ddfb;
    border-radius: 8px 0px 0px 8px;
}

.login-main {
    background: #c8ddfb;
    border-radius: 8px 0px 0px 8px;
}

.twostepauthentication-main {
    background: #f6f7fb;
    /* padding: 15px 100px; */
}

.Resetpwdbg-bg {
    padding: 40px;
    width: 90%;
    height: 100%;
    /* background: #c8ddfb; */
}

.icon-link-socil {
    color: #000000 !important;
    font-size: 20px;
    margin: 0px 3px;
}

.altimate-table {
    overflow-x: auto !important;
    width: 100%;
}

.rolmodel-table {
    overflow-x: auto !important;
    width: 100%;
}

.lifegoal-table {
    overflow-x: auto !important;
    width: 100%;
}

.taskapp-table-Search {
    position: relative;
    top: -39px;
}

.taskapp-tables {
    overflow-x: auto !important;
    width: 100%;
}

.Logosvg {
    width: 100px;
    margin: 7px 7px;
    position: relative;
    height: 66px;
}

.d-lg-block.d-none.main-header-left {
    background: white !important;
}

.Logosvg-auth {
    width: 45px;
    margin: 0px 7px;
    position: relative;
    bottom: 7px;
}

.lifelogo-auth {
    color: #000000;
}

.mynotes-label {
    font-weight: 600;
    font-size: 16px;
}

@media screen and (max-width: 853px) {
    .page-main-header .main-header-right .nav-right>ul>li:first-child {
        width: 81%;
    }
}

@media screen and (max-width: 480px) {

    .taskapp-table-Search {
        position: relative;
        top: -0px;
        margin-bottom: 5px;
    }


    .login-main {
        border-radius: 8px 8px 0px 0px;
    }

    .login-bg {
        border-radius: 8px 8px 0px 0px;
    }

}

.notes-labels{
    font-size: 15px ;
    font-weight: 600;
}


.erkbiK div:first-child , 
.cJTPDY div:first-child{
    white-space: normal !important
}

